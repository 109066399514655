.searchBar {
  display: flex;
  align-self: center;
  justify-content: center;
}

.searchHover {
  background: transparent !important;
  border: 1px solid #c1c1c1 !important;
  color: #c1c1c1 !important;
  outline: none;
  background-size: 22px;
  background-position: 13px;
  border-radius: 10px;
  width: 100px;
  height: 30px !important;
  padding: 15px !important;
  border-radius: 0 !important;
  transition: all 0.5s !important;

  &:hover {
    color: #fff;
  }

  &:focus {
    color: #fff !important;
    background: transparent;
  }
  &:active {
    color: #fff;
  }
  &::placeholder {
    transition: all 0.5s !important;
  }
  &:hover::placeholder {
    color: #fff;
  }
}
.searchIcon {
  height: 30px;
  padding: 15px !important;
  background-color: transparent !important;
  color: #c1c1c1 !important;
  border-radius: 0 !important;
}
.searchResults {
  z-index: 100;
  background-color: transparent !important;
  width: 254px;
  top: 45px;
  position: absolute;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  transition: all 0.5s !important;
  .searchResultItem {
    transition: all 0.5s !important;
    background-color: #212529 !important;
    width: 100%;
    color: #fff;
    border-bottom: 1px solid #c1c1c1;

    &:hover {
      opacity: 0.75 !important;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
