.optionsTable {
  margin: 0 !important;

  tr {
    :last-child {
      text-align: right;
    }
  }

  thead {
    tr {
      th:last-of-type {
        & > * {
          display: block;
          margin-left: -8px;
          width: 100%;
        }
      }
    }
  }

  tfoot {
    tr {
      .formGroup {
        display: flex;
      }
    }
  }
}
