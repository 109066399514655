.controlsContainer {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  padding: 0.25rem 0;

  .controlContainer {
    align-items: center;
    display: flex;
    gap: 0.5rem;
  }
}
