.editorItem {
  --border-style: none;

  position: relative;

  &::after {
    border: 2px var(--border-style) #cccccc;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.activeItem {
  --border-style: solid;
}

.hoveredItem {
  --border-style: dashed;
}
