.container {
  align-items: flex-start;
  color: #ffffff;
  display: flex;
  height: var(--max-content-height);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  .componentNavigator {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    height: 100%;
    padding: 0 0.5rem 2rem;
    overflow: auto;

    .text {
      margin: 0;
      padding: 0 0.5rem;
    }

    .firstComponent {
      padding: 0 1rem;
    }
  }

  .componentNavigatorToggle {
    border-radius: 50%;
    margin-left: 0.25rem;
    margin-top: 0.25rem;
  }
}

button {
  background-color: transparent;
  border: none;
  border: 1px dashed transparent;
  color: currentColor;
  outline: none;
}
