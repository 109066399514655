.container {
  padding-left: 0;
}

.customNav {
  max-height: var(--navbar-height);

  .customNavContainer {
    width: 100%;
    display: flex;
    gap: 6rem;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #fff;

    :first-child {
      margin-right: auto;
    }

    :nth-child(2) {
      flex-grow: 1;
    }

    :last-child {
      align-items: center;
      display: flex;
      gap: 1rem;
      margin-left: auto;

      small {
        border-bottom: 1px solid currentColor;
      }
    }
  }
}

.logo {
  display: block;
  width: 1.5625rem;
  height: 1.6875rem;
  margin-right: 0.5em;
}

.navbarBrand {
  color: #fff !important;
  display: inline-flex;
  gap: 0.5em;

  &:hover {
    color: #fff;
  }

  svg {
    color: #fff;
    width: 29px;
  }
}

.spacer {
  border-left: 1px solid gray;
}
.noBorder {
  border: none !important;
  .btn {
    border: none !important;
  }
  a {
    display: flex !important;
    align-items: center !important;
  }
}
