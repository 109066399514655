$primary: #635dff;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css');

#root {
  --navbar-height: 58px;
  --max-content-height: calc(100vh - var(--navbar-height));
}
