.componentList {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .nestedComponent {
    padding: 0 1rem;
  }
}

.layoutAction {
  align-items: center;
  display: flex;

  &.activeAction {
    background-color: #cccccc;
    color: #303030;
  }

  .componentActions {
    display: flex;
  }
}

.toggleChildrenExpandedAction {
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
}

.hoveredAction {
  border-color: currentColor;
}

.popover {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
